<template>
  <div class="question-page">
    <div class="question-card"
      v-for="(item, index) in questionList"
      :key="index"
      :class="{ open: item.isOpen }">
      <div class="question-title">{{ item.title }}
        <span class="arrow" @click="switchList(item)"></span></div>
      <div class="question-desc" v-html="item.desc"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'QuestionVip',
  data() {
    return {
      questionList: [
        {
          id: 1,
          title: '1.如何确认我开通了会员权益？',
          desc: '会员购买成功后，可前往会员中心页查看会员权益是否生效，如果显示出会员有效期，则表示您已成功开通会员权益。',
        },
        {
          id: 2,
          title: '2.VIP会员的有效期如何查看？',
          desc: '有效期将从购买之日起计算，例如2022年12月26日购买了年度会员，则有效期到2023年12月26日；具体到期时间可以在会员中心查看。',
        },
        {
          id: 3,
          title: '3.购买了会员后所有功能都是免费的吗？',
          desc: '购买了会员之后，会员中心列举的所有会员权益，您都可以在会员有效期内免费使用，权益内容会随着功能的开发而不断扩大。',
        },
        {
          id: 4,
          title: '4.购买会员已扣款但没有及时生效？',
          desc: '购买之后不能使用可能有以下原因：<br />(1)网络延迟，您需要稍等片刻即可正常使用；<br />(2)您关闭APP后，重新开启，检查会员权益是否可用；<br />(3)您退出登录账号，重新登录，检查会员权益是否可用。<br /><br />如果仍未解决问题，您可在【我的>关于我们>微信】中添加客服反馈。',
        },
        {
          id: 5,
          title: '5.购买之后可以退订/更换会员吗？',
          desc: '由于会员权益是数字化商品，基于互联网服务的特殊性和国家相关管理规定，一经购买/开通，立即生效，不支持退款。购买之前请仔细阅读相关服务条款及购买内容，如因您的个人原因发生错误购买或无法正常享受会员权益，小账管家不予退还相应会员费。',
        },
        {
          id: 6,
          title: '6.一个账号购买会员之后可以转移到其他账号吗？',
          desc: '会员购买之后只能用购买时所使用的账号，不可以转移到其他账号。',
        },
      ],
    };
  },
  mounted() {
    document.title = '会员常见问题';
    let { id } = this.$route.query;
    id = id ? Number(id) : id;
    let data = this.questionList.find((item) => item.id === id);
    if (data) {
      this.$set(data, 'isOpen', true);
    }
  },
  methods: {
    switchList(data) {
      let { isOpen } = data;
      this.$set(data, 'isOpen', !isOpen);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./styles/vip.scss";
</style>
